.icon {
  font-size: 2.4rem;
  height: 1em;

  &--x-large {
    font-size: 4rem;
  }

  &--large {
    font-size: 3.2rem;
  }

  &--small {
    font-size: 1.6rem;
  }

  &--x-small {
    font-size: 1.2rem;
  }
}
